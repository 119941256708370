<template>
  <div
    v-if="showOnboardingGuide"
    class="absolute left-[0.35rem] bottom min-w-[21.1rem] max-w-[36.8rem] mt-8 flex gap-8 bg-brand-primary text-white rounded-xl px-12 py-8 z-sticky"
    :class="{
      'left-[unset] right-[0.35rem]': bottomEnd,
      'right-0 bottom-full mb-12 left-auto': topEnd,
      'items-center': noCaption,
      'items-start': !noCaption
    }"
  >
    <i
      class="absolute top-[-0.4rem] left-20 w-[0.6rem] h-[0.4rem] rotate-180"
      :class="{
        'left-[unset] right-20': bottomEnd,
        '!top-full right-[3.8rem] left-auto !rotate-0': topEnd
      }"
    ><img
      src="@/assets/images/common/tail-blue.png"
      class="object-cover w-full h-full"
    /></i>
    <button type="button" class="w-16 h-16 order-2 flex items-center" @click="closeBlueGuide">
      <s-icon srOnlyText="닫기" icon="ic-v2-control-close-line" size="xl" />
    </button>

    <div class="flex-1 flex flex-col gap-4 w-full order-1 min-w-[min(21rem, 100%)]">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    bottomEnd?: boolean;
    storedName?: string;
    topEnd?: boolean;
    noCaption?: boolean;
  }>(),
  {
    bottomEnd: false,
    topEnd: false,
    storedName: 'blue-guide-showed',
    noCaption: false
  }
);

const showOnboardingGuide = ref(true);

const closeBlueGuide = () => {
  if (!showOnboardingGuide.value) {
    return;
  }
  showOnboardingGuide.value = false;
  localStorage.setItem(props.storedName, '1');
};

onBeforeMount(() => {
  const storedValue = localStorage.getItem(props.storedName);

  if (storedValue) {
    showOnboardingGuide.value = false;
  }
});
</script>
